import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const NavigationContainer = styled.div`
  background: #1E1E1E;
  width: 100%;
`;

const InnerContainer = styled.div`
  max-width: 1080px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 45px;
`;

const MainNavigationLink = styled(Link)`
  color: #FFF;
  text-decoration: none;
`;

export const Navigation: React.FC = ({ children }) => {
  return (
    <NavigationContainer>
      <InnerContainer>
        <MainNavigationLink to="/lighthouses">Lighthouses</MainNavigationLink>
        <MainNavigationLink to="/lightships">Lightships</MainNavigationLink>
        <MainNavigationLink to="/daymarks">Daymarks</MainNavigationLink>
        <MainNavigationLink to="/glossary">Glossary</MainNavigationLink>
      </InnerContainer>
    </NavigationContainer>
  )
}
