import * as React from "react";
import styled from "styled-components";

interface IDescriptionProps {
  title: string;
}

const TitleContainer = styled.h1`
  background: #225C4C;
  margin-top: 10px;
  border: 3px solid #1E1E1E;
  color: #FFF;
  padding: 5px 0px;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
  width: 100%;
`;

export const PageTitle: React.FC<IDescriptionProps> = ({ title }) => {
  return (
    <TitleContainer>
      { title }
    </TitleContainer>
  )
}
