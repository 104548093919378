import styled from "styled-components";

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GalleryImage = styled.img`
  border-radius: 8px;
  border: 3px solid #1E1E1E;
  background: pink;
  width: 100%;
`;
