import * as React from "react";
import styled from "styled-components";

interface IDescriptionProps {
  children: React.ReactChildren;
}

const DescriptionContainer = styled.div`
  background: #225C4C;
  margin-top: 10px;
  border: 3px solid #1E1E1E;
  color: #FFF;
  padding: 15px;
  border-radius: 8px;
  width: calc(100% - 30px);

  text-align: justify;
  line-height: 1.3;
`;

export const Description: React.FC<IDescriptionProps> = ({ children }) => {
  return (
    <DescriptionContainer>
      { children }
    </DescriptionContainer>
  )
}
