import React from "react";
import { Header } from "./Header";
import { Navigation } from "./Navigation";
import { Footer } from "./Footer";
import styled from "styled-components";
import "./reset.css";

const ContentContainer = styled.div`
  margin: auto;
  max-width: 1080px;
  padding: 0px 15px;
`;

export const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Navigation />
      <ContentContainer>
        { children }
      </ContentContainer>
      <Footer />
    </>
  );
}
