import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  background: #225C4C;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled(Link)`
  color: #FFF;
  font-size: 40px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  margin: 30px;
`;

export const Header = () => {
  return (
    <HeaderContainer>
      <HeaderText to="/">Worldwide Lighthouses</HeaderText>
    </HeaderContainer>
  )
}
