import styled from "styled-components";

export const MainImage = styled.img`
  border-radius: 8px;
  margin-top: 10px;
  border: 3px solid #1E1E1E;
  width: 100%;
`;

export const MainImageContainer = styled.div`
  width: 67%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TopBox = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
