import * as React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  background: #1E1E1E;
`;

const InnerContainer = styled.div`
  max-width: 1080px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 90px;
  color: #FFF;
`;

export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <InnerContainer>
        &copy; Copyright {new Date().getFullYear()}
      </InnerContainer>
    </FooterContainer>
  );
}
