import * as React from "react";
import { graphql } from 'gatsby';
import { Layout } from "../Layout";
import { Description } from "../Description";
import { InfoBox } from "../InfoBox";
import { PageTitle } from "../PageTitle";
import { Gallery } from "../Gallery";
import { TopBox, MainImageContainer } from "../TopBox";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Head = ({ data }) => {
  const metadata = data.daymark.frontmatter;
  const description = `${metadata.name} Daymark was established in ${metadata.yearEstablished}`;

  return (
    <>
      <title>{metadata.name} Daymark | Worldwide Lighthouses</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={metadata.name} />
      <meta property="og:site_name" content="Worldwide Lighthouses" />
      <meta property="og:url" content="https://worldwidelighthouses.com" />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="place" />
      { 
        data.images.edges
          .map(edge => getImage(edge.node).images)
          .map(image => 
            <meta 
              property="og:image" 
              content={image.fallback.src}
            />
          )
      }
    </>
  )
}

const Daymark = ({ data }) => {
  const daymark = data.daymark;
  const metadata = daymark.frontmatter;

  return (
    <Layout>
      <PageTitle title={metadata.name} />
      <TopBox>
        <MainImageContainer>
        { data.images?.edges?.[0]?.node &&
          <GatsbyImage 
            image={getImage(data.images.edges[0].node)}
            alt={`${metadata.name} Daymark`} 
            style={{ 
              borderRadius: "8px",
              marginTop: "10px",
              border: "3px solid #1E1E1E",
              width: "100%"
            }} 
            imgStyle={{
              borderRadius: "5px"
            }}
          />
        }
        </MainImageContainer>
        <InfoBox
          name={metadata.name}
          yearEstablished={metadata.yearEstablished}
          yearCurrentLighthouseBuilt={metadata.yearCurrentDaymarkBuilt}
        />
      </TopBox>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: daymark.html }} />
      </Description>
      { data.images?.edges && data.images?.edges.length > 0 &&
        <Gallery>
          {data.images.edges.slice(1).map(edge => 
            <GatsbyImage 
              style={{ borderRadius: "8px", border: "3px solid #1E1E1E", width: "100%"}} 
              image={getImage(edge.node)} 
              alt={`${metadata.name} Daymark`}
              imgStyle={{ borderRadius: "5px" }}
            /> 
          )}
        </Gallery>
      }
    </Layout>
  );
};

export default Daymark;

export const Query = graphql`
  query DaymarkQuery($id: String!, $urlEncodedName: String!) {
    daymark: markdownRemark(id: { eq: $id }) {
      html,
      frontmatter {
        name,
        yearEstablished,
        yearCurrentDaymarkBuilt
      }
    }
    images: allFile(
      filter: {
        relativeDirectory: { eq: $urlEncodedName }
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      },
      sort: { order:ASC fields: absolutePath }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 530
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
