import * as React from "react";
import styled from "styled-components";

interface IInfoProps {
  title: string;
  value: string;
}

const InfoContainter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
`;

export const Info: React.FC<IInfoProps> = ({title, value}) => <InfoContainter><div>{title}:</div><div>{value}</div></InfoContainter>;

interface IInfoBoxProps {
  name: string;
  yearEstablished: string;
  yearCurrentLighthouseBuilt: string;
  yearAutomated: string;
  yearElectrified: string;
  heightInFeet: string;
  designer: string;
  operator: string;
}

export const InfoBoxContainer = styled.div`
  background: #225C4C;
  width: 28%;
  margin-top: 10px;
  border: 3px solid #1E1E1E;
  color: #FFF;
  padding: 15px;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
  }
`;

export const InfoBox: React.FC<IInfoBoxProps> = ({ 
  name, 
  yearEstablished, 
  yearCurrentLighthouseBuilt, 
  yearAutomated, 
  yearElectrified, 
  heightInFeet, 
  designer, 
  operator 
}) => {
  return (
    <InfoBoxContainer>
      { name && <Info title="Name" value={name} /> }
      { yearEstablished && <Info title="Established" value={yearEstablished} /> }
      { yearCurrentLighthouseBuilt && <Info title="Current Version Built" value={yearCurrentLighthouseBuilt} /> }
      { yearAutomated && <Info title="Automated" value={yearAutomated} /> }
      { yearElectrified && <Info title="Electrified" value={yearElectrified} /> }
      { heightInFeet && <Info title="Height (ft)" value={heightInFeet} /> }
      { designer && <Info title="Designer" value={designer} /> }
      { operator && <Info title="Operator" value={operator} /> }
    </InfoBoxContainer>
  )
}
